<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div style="display: flex">
      <el-select v-model="searchType" placeholder="请选择查询类别">
        <el-option label="手机号" value="phone"></el-option>
        <el-option label="盒子ID" value="boxid"></el-option>
        <el-option label="iccid" value="iccid"></el-option>
      </el-select>
      <el-input
        placeholder="请选择类别"
        :disabled="true"
        v-if="this.searchType == ''"
      >
      </el-input>
      <div v-else-if="this.searchType == 'phone'">
        <el-select
          v-model="valueArea"
          filterable
          clearable
          placeholder="请选择区号(可搜索关键字)"
          style="width: 240px"
          v-if="internationType"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name + item.tel"
            :value="item.tel"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="phone"
          width="200"
          placeholder="请输入手机号"
        ></el-input>
      </div>
      <el-input
        v-model="boxID"
        width="200"
        v-else-if="this.searchType == 'boxid'"
        placeholder="请输入盒子ID"
      ></el-input>
      <el-input
        v-model="iccid"
        width="200"
        v-else-if="this.searchType == 'iccid'"
        placeholder="请输入iccid"
      ></el-input>
      <el-button type="primary" @click="searchList">查询</el-button>
      <el-button type="info" @click="reset">重置</el-button>
      <el-button type="" @click="refresh">刷新</el-button>
    </div>
    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column prop="phone" label="已绑定手机" width="180">
        </el-table-column>
        <el-table-column prop="boxid" label="盒子ID" width="180">
        </el-table-column>
        <el-table-column prop="iccId" label="iccId" width="180">
        </el-table-column>
        <el-table-column
          prop="firstRequestTime"
          label="首次请求时间"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="bindTime" label="绑定时间"> </el-table-column>
        <el-table-column prop="lastOnlineTime" label="最后联网时间">
        </el-table-column>
        <el-table-column prop="lastRequestTime" label="最后定位时间">
        </el-table-column>
        <el-table-column prop="lastRequestLng" label="最后提交坐标">
        </el-table-column>
        <el-table-column label="语音状态" width="130" align="center">
          <template slot-scope="{ row }">
            {{
              row.cpOpen == true
                ? "已开启声音"
                : row.cpOpen == false
                ? "已关闭声音"
                : "未知"
            }}
            <br />
            <el-button
              @click="handleSet(row, 'open')"
              type="text"
              size="small"
              v-if="row.cpOpen == false"
            >
              开启语音
            </el-button>
            <el-button
              @click="handleSet(row, 'close')"
              type="text"
              size="small"
              v-else-if="row.cpOpen == true"
            >
              关闭语音
            </el-button>
            <el-button type="text" size="small" v-else> 无法设置 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <Pagination
      v-show="pageLength >= 0"
      :total="pageLength"
      :limit.sync="pageSize"
      @pagination="handlePageChange"
      :page-sizes="pageSizes"
    />
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import { showLoading, hideLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";

export default {
  components: { Breadcrumb, Pagination },
  inject: ["reload"],
  data() {
    return {
      boxID: "", // 盒子id
      phone: "",
      iccid: "",

      searchType: "",
      valueArea: "",

      tableData: [],

      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "趣宝盒数据",
        },
        {
          name: "盒子状态",
        },
      ], // 面包屑数据

      internationType: false,
      role: "",
      phoneAreaROLE: "",

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 50, 100, 200, 500, 1000],
    };
  },

  mounted() {
    this.options = areaCodeData;
    this.role = localStorage.getItem("ROLE");

    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.selectLastRequestBox,
      method: "POST",
      params: JSON.stringify({}),
      resFunc: (res) => {
        console.log(res.data.data);

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      if (this.phone || this.boxID || this.searchType) {
        showLoading();
        const opt = {
          url: reqUrl.getBoxList,
          method: "POST",
          params: JSON.stringify({
            hardware: this.boxID,
            iccId: this.iccid,
            nonce: "",
            phone: this.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            sign: "",
            token: "",
          }),
          resFunc: (res) => {
            console.log(res.data.data);

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              this.tableData = [];
              hideLoading();
            } else if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              hideLoading();
            }
            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可查询项",
        });
      }
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.selectLastRequestBox,
        method: "POST",
        params: JSON.stringify({
          hardware: this.boxID,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data.data);

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleSet(row, status) {
      this.$confirm(
        `您确定要${status == "open" ? "开启" : "关闭"}语音设置？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          showLoading();
          const opt = {
            url: reqUrl.boxResetCpStatus,
            method: "POST",
            params: JSON.stringify({
              userId: row.uid,
              open: status == "open" ? true : false,
            }),
            resFunc: (res) => {
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                this.$message.success("设置成功");
                this.refresh();
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
              hideLoading();
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    reset() {
      if (this.phone || this.boxID || this.searchType) {
        this.refresh();
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  margin-right: 10px;
}
.el-input {
  width: 290px;
  margin-right: 20px;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #e6e7e7;
  border-color: #d3d4d6;
  color: #7c7d7e;
}
</style>
